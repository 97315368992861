import { useEffect } from "react";

const Redirect = () => {
    useEffect(() => {
        window.location.href = "/Royale Diapers_Pulse Survey_Rules and Regulations-french.pdf";
    }, []);

    return 0;
};

export default Redirect;